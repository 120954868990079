import type { GetStaticProps } from "next";
import { TEST_FRONT_PAGE_TEST_SITE } from "@/src/gql/queries/pages";
import client from "@/src/apollo/client";
import Block, { NonRepeatableArticleContainers } from "@/src/components/blocks";
import { useContext } from "react";
import { AuthContext } from "./_app";
import { getChannelVideos } from "@/src/utils/youtube";
import GenericHead from "@/src/components/Head/GenericHead";
import { findAllPostIdsFromObjects } from "@/src/utils/helpers";
import { getSpotify } from "@/src/utils/spotify";
import { getRecentPosts } from "@/src/utils/posts";
import LoadMoreContainer from "@/src/components/LoadMoreContainer";

export interface HomeProps {
  props: { data: any; errors: any };
}
export interface SeenArticle {
  id: string;
  uri: string;
}

const Home = (props: HomeProps) => {
  const context = useContext(AuthContext);
  //@ts-ignore
  const blocks =
    //@ts-ignore
    props?.data?.page?.template?.testLayout?.testFlexLayout ?? [];
  //@ts-ignore
  const heroArticleIds = props?.nonRepeatableIds as SeenArticle[];
  return (
    <>
      <GenericHead />
      <div className="bg-white front-page-container" key="front-page-container">
        {blocks?.map(function (block: any, index: number) {
          return (
            <Block
              block={block}
              key={`home-block-${index}`}
              index={index}
              homePageProps={props}
              heroArticleIds={heroArticleIds}
            />
          );
        })}
        {/*//@ts-ignore */}
        {props?.page?.content ? (
          <div
            //@ts-ignore
            dangerouslySetInnerHTML={{ __html: props?.page?.content ?? {} }}
            key="content"
          />
        ) : null}
        <div className={"container mx-auto"} key={"load-more-container"}>
          {/*//@ts-ignore */}
          <LoadMoreContainer
            initialArticles={[]}
            ignoreIds={heroArticleIds.map(function (article) {
              return article.id;
            })}
          />
        </div>
      </div>
    </>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  const { data, errors } = await client
    .query({
      query: TEST_FRONT_PAGE_TEST_SITE,
    })
    .catch((e) => {
      return { data: {}, errors: { e } };
    })
    .then((obj) => obj);
  let videos: any[] = [];

  if (process.env.TEST_YOUTUBE !== "true") {
    videos = await getChannelVideos(2);
    // blokeVideos = await getVideosFromPlaylist(BLOKE_PLAYLIST_ID);
  }
  const spotifyShows = await getSpotify();
  //@ts-ignore
  const nonRepeatableBlocks =
    data?.page?.template?.testLayout?.testFlexLayout.filter((block: object) =>
      //@ts-ignore
      NonRepeatableArticleContainers.includes(block.fieldGroupName)
    );

  const nonRepeatableIds = findAllPostIdsFromObjects({
    objs: nonRepeatableBlocks,
    goodStrings: ["id"],
    badStrings: ["/category/"],
    array: [],
  });

  const tenRecentPosts = await getRecentPosts({
    amount: 5,
    notIn: nonRepeatableIds.map(function (nri) {
      //@ts-ignore
      return nri.id;
    }),
  });

  // Removed because we are not using the most recent posts as a top container - will eed to refactor
  // to determine if we are using a top posts container and then remove the top n posts
  // const recentPostids = findAllPostIdsFromObjects({
  //   objs: [tenRecentPosts],
  //   goodStrings: ["id"],
  //   badStrings: ["/category/"],
  //   array: [],
  // });

  const heroIds = [...nonRepeatableIds];

  return {
    props: {
      data: data ? data : {},
      videos,
      blokeVideos: [],
      nonRepeatableIds: heroIds ?? [],
      spotifyShows,
      recentPosts: tenRecentPosts,
    },
    // revalidate: 60 * 60 * 24,
  };
};

export default Home;
