import React from "react";
import ArticleCard from "../../cards/ArticleCard2";
import Container from "../TopPosts.tsx/Container";

//@ts-ignore
const HeroArticles = ({ block }) => {
  let articleIndex = 0;
  let articleCount = 0;

  block?.heroRepeater?.forEach(function (c: any, colIndex: number) {
    c?.heroArticlePostContainer?.map(function (c: any, index: number) {
      articleCount++;
    });
  });

  return (
    <div className={`px-2md:px-0 my-2`}>
      {block?.heroRepeater?.map(function (col: any, index: number) {
        const isHero = col.hero ? true : false;

        const posts = col?.heroArticlePostContainer?.map(function (
          content: any,
          colIndex: number
        ) {
          if (!isHero) {
            articleIndex = articleIndex + 1;
          }
          const post = content?.heroArticlePostItem?.nodes[0];
          const theCategory = post?.categories?.edges[0];
          const postTitle =
            post.articleOptions.highlight === "category"
              ? theCategory?.node?.name
              : post.articleOptions.frontPageTitle;
          post.title = postTitle;
          return post;
        });

        return (
          <>
            <Container posts={posts} />
          </>
          // <>
          //   {col?.heroArticlePostContainer?.map(function (
          //     content: any,
          //     colIndex: number
          //   ) {
          //     if (!isHero) {
          //       articleIndex = articleIndex + 1;
          //     }
          //     const post = content?.heroArticlePostItem?.nodes[0];
          //     const theCategory = post?.categories?.edges[0];
          //     const postTitle =
          //       post.articleOptions.highlight === "category"
          //         ? theCategory?.node?.name
          //         : post.articleOptions.frontPageTitle;
          //     post.title = postTitle;

          //     return (
          //       <div
          //         className={`article-card ${
          //           isHero ? "article-hero" : `${articleIndex}`
          //         }`}
          //         key={index}
          //       >
          //         <ArticleCard post={post} />
          //       </div>
          //     );
          //   })}
          // </>
        );
      })}
    </div>
  );
};

export default HeroArticles;
